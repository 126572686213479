import { APP_DYNAMICS_APP_KEY } from 'common/AppConstant';
import { useEffect } from 'react';

export default function AppDynamicsRUM() {
  useEffect(() => {
    const APP_DYNAMICS_ADRUM_EXT_URL_HTTP = 'http://cdn.appdynamics.com';
    const APP_DYNAMICS_ADRUM_EXT_URL_HTTPS = 'https://cdn.appdynamics.com';
    const APP_DYNAMICS_BEACON_URL_HTTP = 'http://pdx-col.eum-appdynamics.com';
    const APP_DYNAMICS_BEACON_URL_HTTPS = 'https://pdx-col.eum-appdynamics.com';
    const APP_DYNAMICS_SCRIPT = '//cdn.appdynamics.com/adrum/adrum-24.4.0.4454.js';

    const script = document.createElement('script');
    script.src = APP_DYNAMICS_SCRIPT;
    script.async = true;
    script.type = 'text/javascript';

    const adrumConfig = `
      window["adrum-start-time"] = new Date().getTime();
      (function(config) {
        config.appKey = "${APP_DYNAMICS_APP_KEY}";
        config.adrumExtUrlHttp = "${APP_DYNAMICS_ADRUM_EXT_URL_HTTP}";
        config.adrumExtUrlHttps = "${APP_DYNAMICS_ADRUM_EXT_URL_HTTPS}";
        config.beaconUrlHttp = "${APP_DYNAMICS_BEACON_URL_HTTP}";
        config.beaconUrlHttps = "${APP_DYNAMICS_BEACON_URL_HTTPS}";
        config.useHTTPSAlways = true;
        config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
        config.maxUrlLength = 512;
      })(window["adrum-config"] || (window["adrum-config"] = {}));
    `;

    const scriptConfig = document.createElement('script');
    scriptConfig.textContent = adrumConfig;
    scriptConfig.charset = 'UTF-8';
    script.type = 'text/javascript';

    document.body.appendChild(script);
    document.body.appendChild(scriptConfig);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(scriptConfig);
    };
  }, []);

  return null;
}
